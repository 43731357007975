export const environment = {
  name: 'invictus',
  production: true,
  enablePendo: false,
  enableDatadog: false,
  enableServiceWorker: true,
  enableGoogleAnalytics: false,
  enableFullStory: false,
  applicationUrl: 'https://qa.invic.mims.rewaatech.com',
  webSocketUrl:
    'wss://11n925ze3e.execute-api.us-east-1.amazonaws.com/qainvictus',
  fullViewClientId: '',
  sallaAppClientId: 'c8bd5eac0a286630b953bba63a7ce789',
  zidAppClientId: '440',
  bonatAppClientId: '',
  userPoolId: 'us-east-1_JcYzxmDjk',
  clientId: '4sbkeutemg0ohtcrj3312ff460',
  bonatServiceToken: '',
  bonatRedirectUrl: '',
  cognitoAuthUrl: '',
  s3InventoryBucket:
    'https://s3.amazonaws.com/qa-invic-mims-service-any-item-image/inventory/products/',
  defaultImage: 'default.png',
  enableHttp: true,
  enableIntercom: false,
  tapPublicKey: 'pk_test_jMgIW1ilDhXYSNPQ6BKob8aV',
  s3Bucket: 'https://s3.ap-south-1.amazonaws.com/qa.invic.mims.rewaatech.com/',
  offline_intervals: { product: 2160000, customer: 2160000 },
  offline_config: {
    invoice_limit: { android: 250, others: 300 },
  },
  enableUserGuiding: false,
  enableUserPilot: false,
  launchDarklyClientSideId: '63566a32783f5d11abff4b7d',
  mockLaunchDarkly: false,
  enableAutomationTestFF: false,
  automationTestFFAPIKey: '123',
  pluginUrl: 'http://localhost:5000',
  accountingApiPath: '/api/accounting',
  customFieldApiPath: '/api/custom-field-service',
  firebaseConfig: {
    apiKey: 'AIzaSyDpqm3hlLPwJ5mZmyX_GuGfth1lRWB1fHo',
    authDomain: 'rewaa-test.firebaseapp.com',
    projectId: 'rewaa-test',
    storageBucket: 'rewaa-test.appspot.com',
    messagingSenderId: '181952186125',
    appId: '1:181952186125:web:d9530938a18589a82f9244',
    measurementId: 'G-6XEQD0RRJV',
  },
  intercomAppId: 'sg0ry903',
  enableFirestore: true,
  allowAliasedEmails: true,
  reportDataLimit: 10000,
  reportingApiUrl: 'https://reporting.qa.invic.rewaatech.com/cubejs-api/v1',
  appVersion: require('../../package.json').version,
  nearpayEnvironment: 'sandbox',
};
